import React,{ useEffect } from "react"; 
import { Link, useNavigate, redirect } from 'react-router-dom';


const NextSection = ({title, background, nextPage}) => {

    const navigate = useNavigate();
    function clickToLoad (){
     document.querySelector(".next-page").classList.add("clicked");

     setTimeout(() => { window.scrollTo(0, 0);},1500)
     
     setTimeout(() => {
      navigate( nextPage);
      document.querySelector(".next-page").classList.remove("clicked");
      // window.location.assign(nextPage);
     },2500)

    }



    
  return (
    <div
      onClick={clickToLoad}
      className="next-page pointer"
      style={{
        background: `url(${background}) no-repeat scroll center / cover`,
      }}
    >
      <div className="container">
        <h1>
          {title}
        </h1>
      </div>
    </div>
  );
};

export default NextSection;
