import React from 'react'
import Input from './input/Input';



const ContactForm = () => {

  const onSubmit = (e) => {
e.preventDefault();
  }
  return (
    <form onSubmit={onSubmit}>
      <Input placeholder="Name" name="name" />
      <div className="my-3" />
      <Input
        placeholder="Phone Number"
        name="phone"
        type="tel"
        pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
      />
      <div className="my-3" />
      <Input placeholder="Email Address" name="email" type="email" />
      <div className="my-3" />
      <textarea
        placeholder="Message"
        className="form-control rounded-0"
        rows="5"
      />
      <button type="submit" className="btn-more dark">submit</button>
    </form>
  );
}

export default ContactForm;