import React, { useEffect, useState } from "react";
import { portfolioData } from "../utils/data";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import NextSection from "../components/NextSection";
import PortfolioImagePeel from "../components/PortfolioImagePeel";
import ContactForm from "../components/ContactForm.component";

const PortfolioDetails = () => {
  const location = useLocation();
  const [detail, setDetail] = useState(null);
  const [nextCaseStudy, setNextCaseStudy] = useState(null);

  const path =
    location.pathname.split("/")[2] + "/" + location.pathname.split("/")[3];

  useEffect(() => {
    
    const data = portfolioData.filter(
      (data) => data.slab.toLowerCase() === path.toLowerCase()
    )[0];

    setDetail(data);
  
  }, [path]);

  useEffect(() => {
    const briefDiv = document.querySelector("#brief-content");

    if (briefDiv) briefDiv.innerHTML = detail["design brief"];

    if (detail) {
      const currentIndex = portfolioData.findIndex(
        (data) => data.id === detail.id
      );

      if (currentIndex < portfolioData.length - 1)
        setNextCaseStudy(portfolioData[currentIndex + 1]);
      else setNextCaseStudy(portfolioData[0]);
    }
  }, [detail]);

  useEffect(() => {
    let contentDiv = document.querySelector(".content");
    let innerDiv = document.createElement("div");

    if (detail) {
      contentDiv.classList.add("is-active");

      innerDiv.className = "inner_div";

      contentDiv.appendChild(innerDiv);
      setTimeout(() => {
        document.querySelector("#brief").scrollIntoView();
      }, 3000);
    }

    return () => {
      if (contentDiv.classList.contains("is-active")) {
        contentDiv.classList.remove("is-active");
        contentDiv.removeChild(innerDiv);
      }
    };
  }, [detail, location]);

  return (
    <div className="content">
      {detail && (
        <>
          <HeroSection
            img={detail.images[0]}
            pageTitle={detail.title}
            subTitle={detail.category}
            slab={"Portfolio"}
            description={detail.summery}
            btnText={"view brief"}
            divAnchor={"#brief"}
          />
          <div
            id="brief"
            className="container pb-5 d-flex align-items-center justify-content-center flex-column flex-grow "
            style={{ minHeight: "500px" }}
          >
            <div className="flex-1 w-100">
              {" "}
              <div className="row">
                <div className="col-sm-6 col-md-6"></div>
                <div className="col-sm-6 col-md-6 py-4 ">
                  <div className="pt-5 me-5">
                    <div className="heading heading2 text-capitalize  mb-4 mt-4">
                      Design Brief
                    </div>
                    <div
                      id="brief-content"
                      className="mb-4 small fw-lighter "
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <PortfolioImagePeel image={detail.images[1]} classname="peel" />

          <ServiceRendered services={detail["services offered"]} />
          {detail.images[2] && (
            <div className="mt-5"><PortfolioImagePeel image={detail.images[2]} classname="peel1" /></div>
            
          )}

          <div className="container py-5">
            <div className="row">
              <div className="col-sm-6 col-md-6"></div>
              <div className="col-sm-6 col-md-6 py-4 ">
                <h3>Get in touch</h3>
                <p className="small text-muted fw-100">Find our more about our Creative and Construct services or<br/>
                Contact us to discuss your project.</p>
                <ContactForm />
              </div>
            </div>
          </div>

          {nextCaseStudy && (
            <NextSection
              title={nextCaseStudy?.title}
              nextPage={`/portfolio/${nextCaseStudy.slab}`}
              background={nextCaseStudy?.images[0]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PortfolioDetails;

export const ServiceRendered = ({ services }) => {
  return (
    <div
      className="darkTheme pb-5 d-flex align-items-center justify-content-center flex-column"
      style={{ minHeight: "500px" }}
    >
      <div className="container">
        <div className="row ">
          <div className="col-sm-6 col-md-6"></div>
          <div className="col-sm-6 col-md-6 py-4 ">
            <div className="">
              <div className="heading heading2 text-capitalize  mb-4 mt-4">
                Services Rendered
              </div>
              <div id="service" className=" small fw-lighter ">
                <ul className="m-0 px-3">
                  {services &&
                    services?.map((service, index) => (
                      <li key={index} className="py-2">
                        {service}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
