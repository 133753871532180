import bourbonCoffee from "../assets/projects/bourbon-coffee.jpg";
import bourbon3d from "../assets/projects/bourbon-coffee-3d.jpg";
import bourbonCoffeeRender from "../assets/projects/bourbon-coffee-render.jpg";
import dRender from "../assets/projects/3d-render.jpg";
import renderLolbby from "../assets/projects/3d-render-lobby.jpg";
import renderBar from "../assets/projects/3d-bar-render.jpg";
import pilipiliNightClub from "../assets/projects/pilipili-night-club.jpg";
import pilipili3d from "../assets/projects/pilipili-3d.jpg";
import pilipiliRender from "../assets/projects/pilipili-render.jpg";
import dalbergeproject from "../assets/projects/dalberge-project.jpg";
import dalberge3d from "../assets/projects/dalberge-3d.jpg";
import dalbergeOffice from "../assets/projects/dalberge-office.jpg";
import thomsBar from "../assets/projects/thoms-bar.jpg";
import laperie from "../assets/projects/thoms-laperie-interior.jpg";
import leprive from "../assets/projects/le-prive.jpg";
import lepriveBar from "../assets/projects/leprive-bar.jpg";
import interiorDesign from "../assets/projects/interior-dining.jpg";
import couch from "../assets/projects/cough.jpg";


export const portfolioData = [
  {
    id: "95044erer9030",
    title: "Bourbon coffee",
    summery:
      "Complete redesign and arrangement of the restaurant spaces at Kigali International Airport Client",
    "design brief":
      "Complete redesign and arrangement of the restaurant spaces at Kigali International Airport Client wanted a representation of modern Rwanda and a world-class level restaurant experience for travellers",
    images: [bourbonCoffee, bourbon3d, bourbonCoffeeRender],
    "services offered": [
      "3D Design & technical drawings",
      "Interior design and materials sourcing",
      "Installation and supervision of works",
    ],
    category: "commercial",
    slab: "commercial/bourbon-coffee",
    anchorType: "internal",
  },
  {
    id: "9dfdf449030",
    title: "Pili pili night club goma",
    summery:
      "Full renovation project. Redesign of the counter and seating area",
    "design brief":
      "<p>Full renovation project.</p> <p> Redesign of the counter and seating area.</p><p> The client wanted a play of shapes and eye catching furnishing</p>",
    images: [pilipiliNightClub, pilipili3d, pilipiliRender],
    "services offered": [
      "3D Design & technical drawings",
      "Interior design",
      "Material sourcing",
      "Construction and supervision",
    ],
    category: "commercial",
    slab: "commercial/pili-pili-night-club-goma",
    anchorType: "internal",
  },
  {
    id: "950449030",
    title: "Dalberg global development advisor",
    summery:
      "New office design for Dalberg in Rwanda. The client wanted the space to be functional",
    "design brief":
      "<p>New office design for Dalberg in Rwanda.</p> The client wanted the space to be functional, easily shared by the staff and bring the natural light in.",
    images: [dalbergeproject, dalberge3d, dalbergeOffice],
    "services offered": [
      "3D Design & technical drawings",
      "Construction and supervision",
      "Material sourcing and furnishing installation",
    ],
    category: "commercial",
    slab: "commercial/dalberg-global-development-advisor",
    anchorType: "internal",
  },
  {
    id: "950449035450",
    title: "Le prive",
    summery: "High end restaurant design in Rwanda in a residential complex",
    "design brief":
      "<p>High end restaurant design in Rwanda in a residential complex.<br/> The design was to make the space feel exclusive but homely at the same time with private dinning spaces, a VIP lounge single and group seating spaces. <br/>Earthy and toned colors elevated the space.</p>",
    "services offered": [
      "3D Design and technical drawings",
      "Build and construction",
      "Material and furniture sourcing",
      "Installation",
    ],
    images: [laperie, leprive, lepriveBar],
    category: "commercial",
    slab: "commercial/leprive",
    anchorType: "internal",
  },
];

export const sliderData = [
  {
    img: laperie,
    pageTitle: "Welcome",
    subTitle: "Home",
    slab: "Architectural interior design studio",
    description:
      "Thoms Interior Design opened in 2014 with the main aim of bringing unique and luxurious designs to Rwanda and greater East Aftica,",
  },
  {
    img: bourbonCoffee,
    pageTitle: "Architectural",
    subTitle: "Commercial",
    slab: "Architectural interior design studio",
    description:
      "Commercial- Initial meeting,concept creation, technical drawings based on 3d rendering",
    btnText: "Read more",
    anchor: "/service/architectural-design",
  },
  {
    img: dRender,
    pageTitle: "3D rendering",
    subTitle: "Commercial",
    slab: "Conceptialization",
    description:
      "3D design conceptualize the clients needs and brings the designs from mind to paper",
    btnText: "Read more",
    anchor: "/service/3d-design-rendering",
  },
  {
    img: interiorDesign,
    pageTitle: "interior Design",
    subTitle: "Commercial",
    slab: "Interior",
    description:
      "We create original designs for each project keeping in mind the clients requirements.",
    btnText: "Read more",
    anchor: "/service/interior-design",
  },
  {
    img: couch,
    pageTitle: "Material sourcing",
    subTitle: "Commercial",
    slab: "Internationally curated furnishing",
    description:
      "Locally and internationally curated furnishings are selected from prestigious furniture and furnishing suppliers- like chairs and sofas which are morden and advanced",
    btnText: "Read more",
    anchor: "/service/material-sourcing",
  },
];

export const services = [
  {
    name: "Architectural design",
    images: [bourbonCoffee],
    slab: "service/architectural-design",
    anchorType: "internal",
    description:
      "Commercial - Initial meeting,concept creation, technical drawings based on 3d rendering",
  },
  {
    name: "Interior design",
    slab: "service/interior-design",
    images: [interiorDesign],
    anchorType: "internal",
    description:
      "We create original designs for each project keeping in mind the clients requirements. Our process in capturing in capturing your concept includes; 3D presentation/proposal, fabric approval , construction, furniture curation",
  },
  {
    name: "3d Design rendering",
    slab: "service/3d-design-rendering",
    images: [dRender, renderLolbby,renderBar],
    anchorType: "internal",
    description:
      "3D design conceptualize the clients needs and brings the designs from mind to paper.",
  },
  {
    name: "construction and supervising",
    images: [laperie, leprive, lepriveBar],
    slab: "service/construction-supervising",
    anchorType: "internal",
    description:
      "Our professional team, locally and internationlaly tranied in electrical conncectivity, tiling, ceiling, ensure we acheive our proposal and the plan of work is achieved.",
  },
  {
    name: "Material sourcing",
    slab: "service/material-sourcing",
    images: [couch],
    anchorType: "internal",
    description:
      "Locally sourced and internationally curated materials are chosen for their  professional look and enviromentally friendly durability.",
  },
  {
    name: "furniture supply",
    images: [laperie, leprive, lepriveBar],
    slab: "service/furniture-supply",
    anchorType: "internal",
    description:
      "Locally and internationally curated furnishings are selected from prestigious furniture and furnishing suppliers- like chairs and sofas which are morden and advanced.",
  },
];
