import React, { useEffect } from "react";
import Client from "../components/Client.component";
import ContactForm from "../components/ContactForm.component";
import HeroSection from "../components/HeroSection";
import NextSection from "../components/NextSection";
import img from "../assets/projects/3d-bar-render.jpg";
import renderLobby from "../assets/projects/3d-render-lobby.jpg";
import aline from "../assets/images/aline.png";
import { useMediaQuery } from 'react-responsive';

const AboutUs = () => {
  const isMobile = useMediaQuery({ query : '(max-width : 600px)'});
  return (
    <div className="content">
      <HeroSection
        img={img}
        pageTitle="Thoms"
        subTitle="about"
        slab="architecture and design studio"
        btnText="read more"
        description="At Thoms we believe that design matters"
        divAnchor="#about"
      />
      <div id="about" className="darkTheme">
        <div className="container">
          <div className="row ">
            <div className="col-sm-6 col-md-7">
              <div className="pt-5 me-5" style={{ position: "relative" }}>
                {!isMobile &&   <img
                  src={aline}
                  width="300"
                  style={{
                    position: "absolute",
                    right: "200px",
                    top: "90px",
                    filter: "grayscale(0.7)",
                  }}
                />}
              
              </div>
            </div>
            <div className="col-sm-6 col-md-5 py-4 ">
              <div className="pt-5 me-5">
                <div className="heading heading2 text-capitalize text-white mb-4 mt-4">
                  Thoms interior design
                </div>
                <div className="text-light small fw-lighter">
                  <p>
                    Thoms interior design opened in 2014 with the main aim of
                    bringing unique and luxurious designs to Rwanda and greater
                    East Aftica,
                  </p>
                  <p>
                    We provide Build & Interior architectural design services
                    for residential and commercial industries.
                  </p>
                  <p>
                    We have a team of designers, architects and project managers
                    who ensure your project is understood and delivered
                    professionally and on time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlineBio />
      <CoreValues />
      <Client />
      <div className="py-5">
        <div className="container py-3">
          <div className="row">
            <div className="col-sm-0 col-md-6"></div>
            <div className="col-sm-0 col-md-6">
              <div className="heading heading2">Get in Touch</div>
              <div className="small mb-3 text-lighter">
                To discuss a project or to find out more contact us below
              </div>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      <NextSection
        title="Contact"
        nextPage="/contact"
        background={renderLobby}
      />
    </div>
  );
};

export default AboutUs;

export const CoreValues = () => {
  return (
    <div className="">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7"></div>
          <div className="col-sm-12 col-md-5">
            <div className="py-5 me-5">
              <div className="heading heading2 text-capitalize  mb-4 mt-4">
                Core Values
              </div>
              <div className="core_values small fw-lighter">
                <p>
                  At Thoms Interior Designs we have four core values that we
                  work by
                </p>

                <p className="fw-bold">Integrity | Respect | Design</p>
                <p>
                  We believe it is important to <strong>`Design`</strong> with{" "}
                  <strong>`Respect`</strong> and <strong> `Integrity`</strong>{" "}
                </p>
            
                {/* <p>
                  <strong>Design</strong> - Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua
                </p>
                <p>
                  <strong>Respect</strong> - Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua
                </p>
                <p>
                  <strong>Integrity</strong> - Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua
                </p> */}
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AlineBio = () => {
  return (
    <div className="darkTheme text-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-7"></div>
          <div className="col-sm-12 col-md-5">
            <div className="pb-5 me-5">
              <div className="heading heading2 text-capitalize  mb-4">
                Aline Uwimana
              </div>
              <div className="core_values small fw-lighter">
                <p>
                  Aline Uwimana is the founder and lead designer at Thoms
                  Interior Design. After graduating form Essex University, Aline
                  has used her knowledge and inspiration to change the design
                  industry in Rwanda.{" "}
                </p>
                <p>
                  Under her leadership, Thoms Interior Design has brought to
                  life fresh and authentic designs on the local and
                  international market making her organisation one of the
                  leading design firms in Rwanda{" "}
                </p>
                <p>
                  Her vision has been to bring a touch of modern artistry to all
                  her projects. She travels to acquire more knowledge and
                  inspiration. Some of her travels have led her to 100% design
                  events.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
