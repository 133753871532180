import React, { useEffect } from "react";
import HeroBanner from "../assets/projects/dalberge-office.jpg";
import ContactForm from "../components/ContactForm.component";
import HeroSection from "../components/HeroSection";
import NextSection from "../components/NextSection";
import renderLobby from "../assets/projects/3d-render-lobby.jpg";

import barRender from "../assets/projects/3d-bar-render.jpg";

const Contact = () => {
 

  return (
    <div className="content">
      <HeroSection
        img={renderLobby}
        pageTitle="contact"
        subTitle="contact"
        slab="contact"
        btnText="get in touch"
        description="Contact us and let us know about your project, or find out more
            about our awardd winning services"
        divAnchor="#get-in-touch"
      />

      <div id="get-in-touch" className="py-5">
        <div className="container pt-5" style={{ height: "100vh" }}>
          <div className="row">
            <div className="col-sm-12 col-md-6"></div>
            <div className="col-sm-12 col-md-6">
              <div className="__cont mb-3">
                <div className="mb-2">Thoms Interior Designs</div>
                <div>
                  <strong>Kigali Road, Rwanda</strong>
                </div>
                <div>Plot 24 Macvie Lane</div>

                <div className="mt-0">tel: +250 787 035 778</div>

                <div>
                  <br/>
                  <strong>Haramus, Djibouti</strong>
                </div>
                <div className="mt-0">tel: +253 77 220 697</div>
                <div className="mt-1">email: thomsinteriordesign@gmail.com</div>
              </div>

              <div className="heading heading2">Get in Touch</div>
              <div className="contact-form">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextSection
        title="About us"
        nextPage="/about-us"
        background={barRender}
      />
    </div>
  );
};

export default Contact;
