import React, { useEffect } from "react";

const PortfolioImagePeel = ({ image, classname }) => {
  useEffect(() => {
    let peel = document.querySelector(`.${classname}`);
    
    document.addEventListener("scroll", function () {
      let sh = (peel.getBoundingClientRect().top / 1000) * 100;
      let peelScreen = document.querySelector(`.${classname}-overlay`);

      if (sh > 50) peelScreen.style.width = "50%";
      else if (sh <= 0) peelScreen.style.width = "0%";
      else peelScreen.style.width = sh + "%";
    });

  }, []);


  return (
    <div
      className={`${classname} position-relative`}
      style={{
        background: `url(${image}) no-repeat scroll center / cover`,
        height: "100vh",
        minHeight: "600px",
      }}
    >
      <div
        className={`${classname}-overlay`}
        style={{
          content: "",
          backgroundColor: "#fff",
          position: "absolute",
          height: "100%",
          top: "0",
          right: "0",
        }}
      />
    </div>
  );
};

export default PortfolioImagePeel;
