import React from 'react'

const Input = ({onChange, name, placeholder, type = "type", pattern}) => {
  return (
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      pattern={pattern ? pattern : null}
      className="form-control rounded-0 py-3"
    />
  );
}

export default Input;