import React from "react";
import charis from '../assets/client/charis-white.png';
import charisLogo from '../assets/client/charis.png';
import bourbonCoffee from  '../assets/client/bourbon-coffee-reversed.png';
import bralirwa from '../assets/client/bralirwa.png'

const Client = () => {

  const clients = [charis, bourbonCoffee, bralirwa];

  return (
    <div className="darkTheme py-5">
      <div className="container w-75 py-5">
        <div className="text-center heading heading2 mb-3 text-white">Our Clients</div>
        <div className="row">
          {clients.map((client, index) => (
            <div key={index} className="col-sm-12 col-md-4">
              <div
              className="client py-3 d-flex align-items-center text-center"
               style={{justifyContent:"center"}}
              > <img src={client} /></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Client;
