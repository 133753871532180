import React, { useRef } from "react";
import mainMenu from "../utils/menu.json";
import logo from "../assets/logo/logo-white.png";
import styles from "./styles.module.css";
import clsx from "clsx";

const NavBar = () => {
  const navRef = useRef();
  const { about, services, portfolio, contact } = mainMenu;
  const menuTabs = ["about", "services", "portfolio", "contact"];

  const onToggle = () => {
    navRef.current.classList.toggle("opened");
    let menuDrop = document.querySelector(".menu_drop");
    let firstSubmenu = document.querySelector(".submenu.first");
    let secondSubmenu = document.querySelector(".submenu.second");

    if (navRef.current.classList.contains("opened")) {
      menuDrop.classList.add("open");
    } else {
      menuDrop.classList.remove("open");
      document.querySelector(".main.menu-wrapper").style.transform =
        "translate(0,0)";

      firstSubmenu.classList.remove("open");
      secondSubmenu.classList.remove("open");
      if (firstSubmenu.lastChild.nodeName === "UL")
        firstSubmenu.removeChild(firstSubmenu.lastChild);
      if (secondSubmenu.lastChild.nodeName === "UL")
        secondSubmenu.removeChild(secondSubmenu.lastChild);
    }
  };

  function onClickSubMenuFirst(parentText, e) {
    let secondSubMenu = document.querySelector(".submenu.second");
    let menuWrapper = document.querySelector(".main.menu-wrapper");
    let firstSubMenu = document.querySelector(".submenu.first");
    if (secondSubMenu.classList.contains("open"))
      secondSubMenu.classList.remove("open");

    let childText = String(e.target.innerText).toLowerCase();
    const itemClicked = mainMenu[parentText]["children"][childText];

    if (itemClicked.hasOwnProperty("children")) {
      if (secondSubMenu.lastChild.nodeName === "UL")
        secondSubMenu.removeChild(secondSubMenu.lastChild);
      secondSubMenu.classList.add("open");

      menuWrapper.style.transform = "translate(-60%, 0)";
      firstSubMenu.style.transform = "translate(-25% , 0)";

      let childrenItems = Object.keys(itemClicked.children);

      let ul = document.createElement("ul");

      for (let childItem of childrenItems) {
        let liChildItem = document.createElement("li");
        liChildItem.innerText = childItem;
        liChildItem.classList.add("pointer");
        ul.appendChild(liChildItem);
      }

      secondSubMenu.appendChild(ul);
    } else {
      //Redirect to page
      menuWrapper.style.transform = "translate(-30%, 0)";
      firstSubMenu.style.transform = "translate(0 , 0)";

      window.location.replace(itemClicked.link);
    }
  }

  function onClickMainMenu(e) {
    let menuWrapper = document.querySelector(".main.menu-wrapper");
    let subMenu = document.querySelector(".submenu.first");
    let secondSubMenu = document.querySelector(".submenu.second");
    secondSubMenu.classList.remove("open");

    if (subMenu.classList.contains("open")) subMenu.classList.remove("open");

    const parentText = String(e.target.innerText).toLowerCase();

    const itemClicked = mainMenu[parentText];

    if (itemClicked.hasOwnProperty("hasChildren")) {
      subMenu.classList.add("open");

      if (subMenu.lastChild.nodeName === "UL")
        subMenu.removeChild(subMenu.lastChild);

      menuWrapper.style.transform = "translate(-30%, 0)";

      subMenu.style.width = "25%";
      subMenu.style.transform = "translate(0,0)";

      let innerItems = Object.keys(itemClicked.children);

      let ul = document.createElement("ul");

      for (let element of innerItems) {
        let lIElement = document.createElement("li");

        lIElement.innerText = element;
        lIElement.classList.add("pointer");
        lIElement.addEventListener("click", function (event) {
          onClickSubMenuFirst(parentText, event);
        });
        ul.appendChild(lIElement);
      }

      subMenu.appendChild(ul);
    } else {
      // Here the website redirects to the page
      // subMenu.style.width = "0";
      subMenu.classList.remove("open");
      subMenu.style.transform = "translate(-10%, 0)";
      menuWrapper.style.transform = "translate(0, 0)";
      menuWrapper.style.width = "15%";

      window.location.replace(itemClicked.link);
    }
  }

  return (
    <>
      <nav className={clsx("navbar position-fixed", styles.nav_bar)}>
        <div
          className={clsx(
            "container-fluid d-fdlex align-items-center justify-content-between",
            styles.nav_container
          )}
        >
          <a className="navbar-brand" href="/">
            <img
              src={logo}
              alt="Logo"
              width="150"
              className="d-inline-block align-text-top"
            />
          </a>
          <div className="position-relative">
            <button className="nav-toggle" onClick={onToggle} ref={navRef}>
              <span className="bar-top"></span>
              <span className="bar-mid"></span>
              <span className="bar-bot"></span>
            </button>
          </div>
        </div>
      </nav>
      <div className={clsx("menu_drop")}>
        <div
          className="main menu-wrapper"
          style={{ transform: "translate(20%, 0%) matrix(1, 0, 0, 1, 0, 0)" }}
        >
          <ul>
            {menuTabs.map((item, index) => (
              <li
                key={index}
                className="pointer"
                onClick={(e) => onClickMainMenu(e)}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div className="submenu menu-wrapper first">
          <div className="inner-line"></div>
        </div>
        <div className="submenu menu-wrapper second">
          <div className="inner-line"></div>
        </div>
        <div className="socials">
          <div className="container">
            <div className="d-flex align-items-center justify-content-end text-light">
              <a
                href="https://www.facebook.com/thomsinteriordesign"
                target="_blank"
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/thomsinteriordesign"
                target="_blank"
              >
                <i className="fa-brands fa-square-instagram"></i>
              </a>
              <a href="https://twitter.com/Thomsinteriors" target="_blank">
                <i className="fa-brands fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
