import React, { useEffect } from "react";
import imgs from "../assets/projects/dalberge-project.jpg";
import { Link, useLocation } from "react-router-dom";

const HeroSection = ({
  img = null,
  pageTitle = null,
  subTitle = null,
  slab = null,
  btnText = null,
  description = null,
  divAnchor = null,
  anchorType = "external",
}) => {
  const pathname = useLocation().pathname;

  useEffect(() => {
    let contentDiv = document.querySelector(".content");
      let innerDiv = document.createElement("div");
    if(contentDiv){
      if (contentDiv.classList.contains("is-active"))
        contentDiv.classList.remove("is-active");



      innerDiv.className = "inner_div";

      contentDiv.appendChild(innerDiv);
      setTimeout(() => {
        contentDiv.classList.add("is-active");
      }, 1000);
    }

    return () => {
      if(contentDiv){
        contentDiv.classList.remove("is-active");
        contentDiv.removeChild(innerDiv);
      }
    };
  }, [pathname]);

  return (
    <div
      className="hero vh-100 d-flex align-items-end justify-content-end flex-colunm position-relative"
      style={{
        background: `url(${img}) no-repeat scroll center / cover`,
      }}
    >
      <div className="container py-5 text-white hero-inner">
        {subTitle && (
          <div className="d-flex align-items-center small mb-3 __slab">
            <span className="text-uppercase fw-bold subtitle">{subTitle}</span>
            <span className="subtitle_before mx-3"></span>
            <span className="text-uppercase">{slab}</span>
          </div>
        )}
        {pageTitle && (
          <div id="page-title" className="text-uppercase mb-3">
            {pageTitle}
          </div>
        )}

        {description && (
          <div className="hero_descript mb-3 text-light">{description}</div>
        )}
        {divAnchor || btnText ? (
          anchorType === "internal" ? (
            <Link to={divAnchor} className="btn-more mb-3">
              <span>{btnText}</span>
              <div className="left"></div>
              <div className="top"></div>
              <div className="right"></div>
              <div className="bottom"></div>
            </Link>
          ) : (
            <a href={divAnchor} className="btn-more mb-3">
              <span>{btnText}</span>
              <div className="left"></div>
              <div className="top"></div>
              <div className="right"></div>
              <div className="bottom"></div>
            </a>
          )
        ) : null}
      </div>
    </div>
  );
};

export default HeroSection;
