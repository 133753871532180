import React, { useEffect, useState } from "react";
import { services } from "../utils/data";
import { useLocation } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import PortfolioImagePeel from "../components/PortfolioImagePeel";
import ContactForm from "../components/ContactForm.component";
import NextSection from "../components/NextSection";

const ServiceDetails = () => {
  const [details, setDetails] = useState();
  const [nextCaseStudy, setNextCaseStudy] = useState(null);
  const location = useLocation();

  const path =
    location.pathname.split("/")[1] + "/" + location.pathname.split("/")[2];

  useEffect(() => {
    const service = services.filter(
      (data) => data.slab.toLowerCase() === path
    )[0];

    setDetails(service);
  }, [path]);
  useEffect(() => {
    const briefDiv = document.querySelector("#brief-content");

    if (details) {
      const currentIndex = services.findIndex((data) => data.slab === path);

      if (currentIndex < services.length - 1)
        setNextCaseStudy(services[currentIndex + 1]);
      else setNextCaseStudy(services[0]);
    }

        setTimeout(() => {
          document.querySelector("#brief").scrollIntoView();
          
        }, 3000);
  }, [details, path]);

  return (
    <div className="content">
      {details && (
        <>
          <HeroSection
            img={details.images[0]}
            pageTitle={details.name}
            subTitle={details.category}
            slab={"Service"}
            description={details.description}
            btnText={"view brief"}
            divAnchor={"#brief"}
          />
          <div
            id="brief"
            className="darkTheme d-flex align-items-center justify-content-center flex-column flex-grow "
            style={{ minHeight: "500px" }}
          >
            <div className="flex-1 container w-100">
              <div className="row">
                <div className="col-sm-6 col-md-8"></div>
                <div className="col-sm-6 col-md-4 ">
                  <div className="pt-2 me-5">
                    <div className="heading heading2 text-capitalize  mb-4 mt-4">
                      {details.name}
                    </div>
                    <div id="brief-content" className="mb-4  fw-lighter ">
                      {" "}
                      {details.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {details.images[1] && (
            <PortfolioImagePeel image={details.images[1]} classname="peel" />
          )}

          <div className="container py-5">
            <div className="row">
              <div className="col-sm-6 col-md-6"></div>
              <div className="col-sm-6 col-md-6 py-4 ">
                <h3>Get in touch</h3>
                <p className="small text-muted fw-100">
                  Find our more about our Creative and Construct services or
                  <br />
                  Contact us to discuss your project.
                </p>
                <ContactForm />
              </div>
            </div>
          </div>

          {nextCaseStudy && (
            <NextSection
              title={nextCaseStudy?.name}
              nextPage={`/${nextCaseStudy.slab}`}
              background={nextCaseStudy?.images[0]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ServiceDetails;
