import React, { useMemo } from "react";
import HeroSection from "../components/HeroSection";

import {sliderData} from "../utils/data";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { useEffect } from "react";

const Home = () => {
  

  return (
    <Splide
      options={{
        // rewind: true,
        // type: "loop",
        // autoplay: true,
        speed: 2000,
        interval: 1000,
        easing: "ease-in-out",
      }}
      aria-label="Thoms interior desgin"
    >
      {sliderData.map((slide, index) => (
        <SplideSlide key={index}>
          <HeroSection
            img={slide.img}
            pageTitle={slide.pageTitle}
            subTitle={slide.subTitle}
            slab={slide.slab}
            description={slide.description}
            btnText={slide.btnText}
            anchorType="internal"
            divAnchor={slide.anchor}
          />
        </SplideSlide>
      ))}

      <div className="splide__progress">
        <div className="splide__progress__bar" />
      </div>
    </Splide>
    
  );
};

export default Home;
