import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { portfolioData as rawData} from "../utils/data";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import HeroSection from "../components/HeroSection";
const Portfolio = () => {
  const location = useLocation();
  const [portfolioData, setPortfolioData] = useState([]);


  useEffect(() => {
    const query = location.pathname.split("/")[2];
    const data = rawData.filter(
      (data) => data.category === query.toLowerCase()
    );
    const sliderItems = [];

    data.forEach((item) => {
      if (item.images.length > 0) {
        sliderItems.push(item);
      }
    });
    setPortfolioData(sliderItems);
  }, []);


  return (
    <>
      {portfolioData ? (
        portfolioData.length > 0 ? (
          <Splide
            options={{
              // rewind: true,
              // type: "loop",
              // autoplay: true,
              speed: 2000,
              interval: 1000,
              easing: "ease-in-out",
            }}
            aria-label="Thoms interior desgin"
          >
            {portfolioData.map((slide) => (
              <SplideSlide key={slide.id}>
                <HeroSection
                  img={slide.images[0]}
                  pageTitle={slide.title}
                  subTitle={slide.category}
                  slab={"Portfolio"}
                  description={slide.summery}
                  anchorType={slide.anchorType}
                  btnText={"view project"}
                  divAnchor={`/portfolio/${slide.slab}`}
                />
              </SplideSlide>
            ))}
          </Splide>
        ) : (
          <div
            className="d-flex alin-items-center justify-content-center flex-column text-white"
            style={{ height: "100vh", backgroundColor: "#1B1B1E" }}
          >
            <div className="text-center">
              <h1>404</h1>
              <h2>Sorry, the page you're looking for doest not exist</h2>
              <p>
                Go back to the <a>home page</a>
              </p>
            </div>
          </div>
        )
      ) : (
        "loading"
      )}
    </>
  );
};

export default Portfolio;
